import React from "react"
import { Link, navigate } from "gatsby"
import { getUser, isLoggedIn, logout } from "../services/auth"
import { Button, Form, FormControl, Nav, Navbar } from "react-bootstrap"
import { SterfiveLogo } from "./SterfiveLogo"

import Search from "./search"
const searchIndices = [{ name: `tuto`, title: `Pages` }]

export default function SterfiveNavbar() {
  let greetingMessage = ""
  if (isLoggedIn()) {
    greetingMessage = `Hello ${getUser()!.fullName}`
  } else {
    greetingMessage = "You are not logged in"
  }
  return (
    <Navbar collapseOnSelect bg="dark" variant="dark" sticky="top">
      <Navbar.Brand href="/">
        <SterfiveLogo style={{ height: "25px", margin: "10px 1 1 1" }} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="https://www.sterfive.com">Company</Nav.Link>
          {!isLoggedIn() && <Nav.Link href="/app/features">Features</Nav.Link>}
          {!isLoggedIn() && <Nav.Link href="/app/pricing">Pricing</Nav.Link>}
          {isLoggedIn() && <Nav.Link href="/app/profile">Profile</Nav.Link>}
        </Nav>
        <Nav>
          {isLoggedIn() ? (
            <React.Fragment>
              <Nav.Link>{greetingMessage}</Nav.Link>
              <Nav.Link
                href="/"
                onClick={event => {
                  event.preventDefault()
                  logout(() => navigate(`/app/login`))
                }}
              >
                Logout
              </Nav.Link>
              <Search indices={searchIndices} />
            </React.Fragment>
          ) : (
            <Nav.Link href="/app/login">Login</Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>

      {/* <Form inline>
        <FormControl type="text" placeholder="Search" className="mr-sm-2" />
        <Button variant="outline-info">Search</Button>
      </Form> */}
    </Navbar>
  )
}
