import { getStandardBookPlans } from "../utils/product_description"

export const isBrowser = () => typeof window !== "undefined"

const USER_DATA = "userdata"
export interface Subscription {
  active: boolean
  expiresAt: number
  plan: { id: string; name: string }
}
export interface CurrentMember {
  address: { city: string; street: string; postalCode: string; country: string }
  creditCard: { expMonth: number; expYear: number }
  customField: string
  downloads: []
  email: string
  fullName: string
  id: string
  phoneNumber: string
  subscriptions: Subscription[]
  unrestrictedAccess: boolean
}
export function getUser(): CurrentMember {
  return isBrowser() && window.sessionStorage.getItem(USER_DATA)
    ? JSON.parse(window.sessionStorage.getItem(USER_DATA)!)
    : ""
}
export const setUserData = (user: CurrentMember | null) => {
  console.log(user)
  window.sessionStorage.setItem(USER_DATA, user ? JSON.stringify(user) : "")
}

// export const handleLogin = ({ username, password }) => {
//   if (username === `john` && password === `pass`) {
//     return setUser({
//       username: `john`,
//       name: `Johnny`,
//       email: `johnny@example.org`,
//     })
//   }
//   return false
// }

export const isLoggedIn = () => {
  const user = getUser()
  return user && !!user.email && !!user.id && !!user.unrestrictedAccess
}
export const logout = callback => {
  setUserData(null)
  callback()
}
export function isAuthenticated() {
  return isLoggedIn()
}
export function hasValidPlanSubscription({ name, id }) {
  if (!isAuthenticated()) {
    return false
  }
  const subscriptions = getUser().subscriptions

  const p = getStandardBookPlans()
  const selected = subscriptions.filter(
    (s: Subscription) => p.indexOf(s.plan.id) >= 0
  )
  return selected.length === 1
}
