import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { ClientAlarm } from "node-opcua"
/// <reference types= "../utils/im.d.ts">
import "../utils/im"
import sterfiveLogo from "../images/sterfive3.png"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

export const SterfiveLogo2 = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "sterfive3.png" }) {
        childImageSharp {
          fluid(maxWidth: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return (
    <Img
      style={{ ...props.style, objectFit: "contain" }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  )
}

export const SterfiveLogo = (props: any) => {
  return <img src={sterfiveLogo} style={{ height: "30px" }}></img>
}
export default SterfiveLogo
