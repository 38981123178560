import React from "react"
import { Link } from "gatsby"
import { Navbar } from "react-bootstrap"

export const Footer = () => {
  return (
    <React.Fragment>
      <div style={{ height: "2em" }}></div>
      <Navbar fixed="bottom" bg="dark">
        <span style={{ color: "white", fontSize: "0.7em" }}>
          built with Gatsby -{` `}
          <a href="https://www.sterfive.com">
            copyright sterfive © {new Date().getFullYear()}
          </a>
        </span>
      </Navbar>
    </React.Fragment>
  )
}
