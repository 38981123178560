/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./Header"
import { Footer } from "./Footer"

import "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import "./Layout.css"
import SterfiveNavbar from "./SterfiveNavbar"

const Layout = ({ children }) => {
  return (
    <>
      <SterfiveNavbar />
      {/* <Header /> */}
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
