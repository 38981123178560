import freelanceLogo from "../images/freelance.png"
import smallBusinessLogo from "../images/icons8-launch-96.png"
import largeBusinessLogo from "../images/icons8-skyscrapers-96.png"
import communityLogo from "../images/icons8-community-96.png"
import React from "react"

export const features: {
  title: string
  description: string
  package: string
}[] = [
  {
    title: "community support",
    description:
      "Get help from the community of node-opcua users. Post your question on StackOverflow or chat with other developers on gitter.",
    package: "ABCD",
  },
  {
    title: "professional support",
    description:
      "This subscription tier enables you to get professional support from Sterfive. You'll learn quickly how to best used node-opcua, You'll get you issues fixed by us and you will never get stuck.",
    package: "aBCD",
  },
  {
    title: "for solo developer.",
    description:
      "This tier is ideal if you are a Freelance developer or if you are a solo developer using or experimenting with node-opcua",
    package: "-B--",
  },
  {
    title: "dedicated support portal.",
    description:
      "This tier grant you access to our support portal where you can post issues and ask question. Anything you post here will be private.",
    package: "aBCD",
  },
  {
    title: "small team (1 to 5)",
    description:
      "This tier will grant access to our dedicated support portal for up to 5 people in your team",
    package: "--C-",
  },
  {
    title: "larger team ",
    description:
      "As a enterprise, you'll be entitled to multiple user access to our dedicated support portal",
    package: "---D",
  },

  {
    title: "includes 0 issue",
    description: "This tier does not entitled you for posting support request ",
    package: "a---",
  },
  {
    title: "up to 2 issues per month",
    description:
      "This tier allows you to raised 2 specific issues or support request per calendar month. But you can ask additional support for a competitive fee by  the hours.",
    package: "-B--",
  },
  {
    title: "up to 6 issues per month",
    description:
      "This tier allows you to raised up 6 specific issues or support request per calendar month. You can call us for additional support or upgrade your plan",
    package: "--C-",
  },
  {
    title: "unlimited issues",
    description:
      "This tiers allows you to raised any number of specific issues or support request per calendar month.",
    package: "---D",
  },
  /*
  {
    title: "access to consulting",
    description: "Book access to our expert for a one 2 one session",
    package: "a---",
  },
  {
    title: "access to consulting",
    description:
      "Get additional support for a additional fee. you can book access to our expert for a one 2 one session. Contact us for pricing.",
    package: "-B--",
  },
  {
    title: "access to consulting",
    description:
      "Get additional support for a additional fee. you can book access to our expert for a one 2 one session. Contact us for pricing.",
    package: "--C-",
  },
  {
    title: "access to consulting",
    description:
      "Get additional support for a additional fee. you can book access to our expert for a one 2 one session. Contact us for pricing.",
    package: "---D",
  },
*/
  {
    title: "Online tutorial books.",
    description: `get access to the latest version on the node-opcua by example book
       and to extra materials and documentation about advanced modelling 
       with node-opcua, security and certification.`,
    package: "aBCD",
  },
  {
    title: "pair-programming session.",
    description:
      "If you are stuck or if you need specific training on OPCUA or node-opcua, we can arrange a dedicated one to one session using web conference and get it sorted for you",
    package: "aBCD",
  },
  {
    title: "access to pool of expert",
    description:
      "your issue will be processed by one of our node-opcua dedicated developer",
    package: "aB--",
  },
  {
    title: "ask the author",
    description:
      "This tier gives you a direct access to the node-opcua author, so you can ask him tricky question or make special requests",
    package: "a-CD",
  },

  {
    title: "live code-reviews",
    description:
      "You can request us to review your code that implements node-opcua in your application and give you hints and advice to optimize it. We will ensure that your implementation matches the highest standard. we may have sign an NDA before engaging with you. ",
    package: "abCD",
  },
  {
    title: "access to extra modules",
    description:
      "In this tier you are entitled to get access to additional  node-opcua  modules that we reserve to our customers (such as a node-opcua-web to  web-browser, gds, pubsub etc...). please contact us for the list of available modules and licensing options",
    package: "abCD",
  },
  {
    title: "faster response timer",
    description:
      "Your membership entitles you to fast response time from us so you can get your critical issue sorted out without delay",
    package: "abCD",
  },
  {
    title: "road-map voting right",
    description:
      "As a premium member of the enterprise tier, you will be consulted directly by our management team to contribute and vote for the feature you would like us to develop in the next version of node-opcua and related modules",
    package: "abcD",
  },
  {
    title: "Ask-the-expert webinars",
    description:
      "As a member of this tier, you get full access to our quarterly webinar where we expose an advance OPCUA technics fully illustrated with live examples",
    package: "abcD",
  },
  {
    title: "Business Invoice",
    description: "",
    package: "---D",
  },
]

interface IBundle {
  title: string
  description: string
  price?: [string, string, string]
  plan?: string
  logo: any
  button?: { text: string; url: string }
  plans?: {
    monthly: {
      price: [string, string, string]
      plan: string
    }
    semester: {
      price: [string, string, string]
      plan: string
    }
    yearly: {
      price: [string, string, string]
      plan: string
    }
  }
}
export const bundles: { [key: string]: IBundle } = {
  A: {
    logo: <img src={communityLogo}></img>,
    title: "Community",
    description:
      "Reports your issue to the github or Stack-overflow and get help from the community. ",
    price: ["free", "", ""],
    plan: "",
    button: {
      text: "Ask the community",
      url: "https://github.com/node-opcua/node-opcua/issues",
    },
  },
  B: {
    logo: <img style={{ width: "96px" }} src={freelanceLogo}></img>,
    title: "Individual",
    price: ["199", "€", "/month"],
    plan: "56801",
    plans: {
      monthly: {
        price: ["199", "€", "/month"],
        plan: "56801",
      },
      semester: {
        price: ["1095", "€", "/month"],
        plan: "56802",
      },
      yearly: {
        price: ["2189", "€", "/month"],
        plan: "56803",
      },
    },
    description:
      "For professional developers that want to benefit from our professional support",
  },
  C: {
    logo: <img src={smallBusinessLogo}></img>,
    title: "small business",
    price: ["499", "€", "/month"],
    plan: "56804",
    description:
      "for projects or startups that relies on node-opcua technology in their solutions.",
    plans: {
      monthly: {
        price: ["499", "€", "/month"],
        plan: "56804",
      },
      semester: {
        price: ["2745", "€", "/month"],
        plan: "56806",
      },
      yearly: {
        price: ["5489", "€", "/month"],
        plan: "56805",
      },
    },
  },
  D: {
    logo: <img src={largeBusinessLogo}></img>,
    title: "Enterprise",
    price: ["", "€", "get a quote"],
    description:
      "For larger teams that require advanced support and  influence the development road-map.",
    button: {
      text: "Contact us",
      url: "https://wwww.sterfive.com",
    },
  },
}
export function getStandardBookPlans() {
  const plans: string[] = []
  for (const [key, bundle] of Object.entries(bundles)) {
    if (bundle.plans) {
      plans.push(bundle.plans.monthly.plan)
      plans.push(bundle.plans.semester.plan)
      plans.push(bundle.plans.yearly.plan)
    }
  }
  return plans
}
export function getAdvancedBookPlans() {
  const plans: string[] = []

  let bundle = bundles["D"]
  if (bundle.plans) {
    plans.push(bundle.plans.monthly.plan)
    plans.push(bundle.plans.semester.plan)
    plans.push(bundle.plans.yearly.plan)
  }
  return plans
}
